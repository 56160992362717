#pricing {

    @include layout-content-fullwidth;

    /* ----- Blob ----- */
    .blob-1 { 
        @include decoration(250px, initial, initial, -75px, -1, 190); 
    }
    .blob-2 { 
        @include decoration(-175px, 50px, initial, initial, -1, 90);
     }
    .blob-3 { 
        @include decoration(initial, 250px, 200px, initial, -1, 150);
    }
    .blob-4 { 
        @include decoration(initial, 33%, -150px, initial, 2, 305);
        @include max-lg-rwd { bottom: -200px; }
    }
    .blob-5 { 
        @include decoration(initial, initial, 275px, -85px, -1, 95); 
    }

    /* ----- Dots ----- */
    .dots-1 { 
        @include decoration(initial, 150px, -200px, initial, -1, 0);
    }
    .dots-2 { 
        @include decoration(initial, -50px, 150px, initial, -1, 0); 
        @include max-lg-rwd { 
            top: -115px;
            right: 20px;
            bottom: initial;
        }
    }

    /* ----- Pricing ----- */
    .page-section-1 {
        &.pt-hero-banner { padding-top: calc(6rem + #{$header-mh} + #{$banner-promotion}); }
        &.pt-hero { padding-top: calc(6rem + #{$header-mh}); }

        .block-item {
            border: 1px solid $theme-terthiary;

            @include min-xl-rwd {
                max-width: calc(25% - 1.5rem);
                margin-right: 1rem;
                margin-left: 1rem;

                &:first-child { margin-left: 0; }
                &:last-child { margin-right: 0; }
            }
            @include max-xl-rwd {
                max-width: calc(50% - 1.5rem);

                &:nth-child(odd) { margin-right: 1.5rem; }
                &:nth-child(even) { margin-left: 1.5rem; }
            }
            @include max-md-rwd {
                max-width: 100%;
                margin-right: 0 !important;
                margin-left: 0 !important;
            }

            svg.information-icon { 
                width: 14px;
                fill: $theme-color-primary
            }

            &-popular {
                background-color: $primary;
                border-color: $primary;
                color: $theme-color-secondary;
                @include min-xl-rwd { transform: scale(1.05); }

                &-banner {
                    min-width: 100px;
                    transform: rotate(45deg);
                    right: -25px;
                    top: 12px;
                }

                svg { fill: $theme-color-secondary !important; }

                button > a {
                    background-color: $theme-secondary;
                    &:hover, &:focus { background-color: rgba($theme-secondary, .75) !important; }
                }
            }
        }

        .pricing {
            border-top: 1px solid $theme-terthiary;
            border-bottom: 1px solid $theme-terthiary;

            .price-yearly {
                text-decoration: line-through;
                @include fz-24;
                @include fw-600;
            }
        }

        .pricing-features-list { @include features-pack; }
    }

    /* ----- Features ----- */
    .page-section-2 {
        margin-top: 12rem;
        background-color: $theme-secondary;

        .wave-top-4 { @include waveSeparator(149px, -149px, 0, initial, initial, 1, 0); }
        .wave-bottom-4 { @include waveSeparator(149px, initial, initial, -149px, 0, 1, 180); }

        .block-item {
            min-height: 100%;
            @include min-lg-rwd {
                max-width: calc(33.333333% - 2rem);
                margin-right: 1.5rem;
                margin-left: 1.5rem;

                &:first-child { margin-left: 0; }
                &:last-child { margin-right: 0; }
            }
        }
    }

    /* ----- FAQ ----- */
    .page-section-3 {
        margin-top: 12rem;
    }

}