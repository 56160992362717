 /* ----- Sign Up ----- */
#signUp {

    .blob-1 { 
        @include decoration(initial, initial, -20px, -60px, -1, 190);
        @include max-lg-rwd { right: 0; bottom: -80px; left: initial; }
    }
    .blob-2 { 
        @include decoration(50px, initial, initial, -65px, -1, 190);
    }
    .blob-3 { 
        @include decoration(initial, 10%, 15%, initial, -1, 0);
    }

    .dots-1 { 
        @include decoration(200px, -90px, initial, initial, -1, 0);
    }

}

/* ----- Sign in ----- */
#signIn {

    .blob-1 { 
        @include decoration(40px, initial, initial, -30px, -1, 90);
    }
    .blob-2 { 
        @include decoration(100px, 8%, initial, initial, -1, 0);
    }
    .blob-3 { 
        @include decoration(initial, -65px, 50px, initial, -1, 0);
        @include max-sm-rwd { right: -30px; }
    }

    .dots-1 { 
        @include decoration(initial, initial, -75px, 150px, -1, 0);
        @include max-xl-rwd { left: 25px; }
    }
}

/* ----- Forgot Password ----- */
#forgotPassword {

    .blob-1 { 
        @include decoration(50%, initial, initial, -50px, -1, 0);
    }
    .blob-2 { 
        @include decoration(150px, -105px, initial, initial, -1, 50);
    }
    .blob-3 { 
        @include decoration(initial, 8%, -50px, initial, -1, 90);
    }

    .dots-1 { 
        @include decoration(initial, initial, 10%, -70px, -1, 0);
    }
    .dots-2 { 
        @include decoration(200px, -75px, initial, initial, -2, 0);
    }

}