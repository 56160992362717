.StripeElement {
    min-height: $input-height;
    height: $input-height;
    padding: 12px;
    width: 100%;
    color: $theme-color-primary;
    background-color: $theme-secondary;
    border: 1px solid $theme-terthiary;
    border-radius: .25rem;
    box-sizing: border-box;
    transition: .2s ease-in-out;

    &--focus { border-left: 4px solid $primary; }
    &--invalid { border-color: $danger; }
    &--webkit-autofill { background-color: $theme-secondary; }
}