@mixin bg-img($pos, $size, $height, $top, $right, $bottom, $left, $index) {
    content: '';
    background-repeat: no-repeat;
    background-position: $pos;
    background-size: $size;
    width: 100%;
    height: $height;
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    z-index: $index;
}

@mixin decoration($top, $right, $bottom, $left, $index, $rotate) {
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    z-index: $index;
    transform: rotate($rotate + deg)
}

@mixin waveSeparator($height, $top, $right, $bottom, $left, $index, $rotate) {
    width: 100%;
    height: $height;
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    z-index: $index;
    overflow: visible;
    transform: rotate($rotate + deg);
}