#footer {
    background-color: $theme-color-primary;

    .container > div:not(:last-child) { border-bottom: 1px solid $white-50; }

    p { color: $theme-color-secondary; }

    .footer-title {
        margin-bottom: 1.5rem;
        font-weight: 600;
        @include fz-18;
    }

    ul {
        li {
            margin-left: -.5rem;
            &:not(:last-child) { margin-bottom: .5rem; }
            @include max-lg-rwd { 
                text-align: center;
                margin: 0 auto;
            }

            .footer-link, &.footer-link {
                @include menu-link-style(.5, .5, $theme-color-secondary);
                @include min-lg-rwd { width: fit-content; }
                cursor: pointer;
            }
        }

        &.languages-wrapper {
            li {
                @include max-lg-rwd {
                    margin-bottom: 0;
                    &:not(:last-child) { margin-right: .5rem; }
                }
            }
        }

        &.social-media-wrapper {
            li {
                margin-bottom: 0;
                &:not(:last-child) { margin-right: 1.5rem !important; }

                a {
                    width: 32px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: .25rem;
                    transition: .2s ease-in-out;

                    &:hover, &:focus { background-color: $md-grey-35; }

                    svg { min-width: 18px; }
                }
            }
        }
    }

    .form-newsletter {
        .checkbox-primary:not(:checked) + label::before { border-color: $theme-color-secondary; }
    }
}