.back-to-top {
    left: 4px;
    margin: 1rem !important;
    padding: 0;
    transform: rotate(90deg);
    background-color: $primary !important;
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 97;
    transition: .2s ease-in-out !important;
    // box-shadow: 8px 0 16px $black-50;
    &:hover, &:focus { 
        background-color: rgba($primary, .9) !important;
        outline: none;
    }
}