.faq-contact-infos {
    background-color: $theme-secondary;
    border: 1px solid $theme-terthiary;

    .faq-contact-infos-block { 
        height: fit-content;

        a {
            color: $theme-color-primary;
            border: 1px solid $theme-terthiary;
            transition: .2s ease-in-out;
            
            &:hover, &:focus {
                text-decoration: none;
                border-color: $primary !important;
                color: $primary;
                svg { fill: $primary; }
            }
        }
    }
}