#navbar, .nav {
    height: $header-mh;
    background-color: $white;
    border-bottom: 1px solid $theme-terthiary;
    z-index: 99;
    top: 0;
    right: 0;
    transition: .4s ease-in-out;
    @include max-lg-rwd { top: 0 !important; }

    &.banner-showed { top: $banner-promotion; }

    &.nav-funnel {
        .nav-link { margin-right: 0; }
    }

    .logo-asking-franklin {
        width: 200px;
        transition: .4s ease-in-out;
        @include max-sm-rwd { width: 150px; }
    }

    .nav-link {
        margin-right: 1rem;
        @include min-lg-rwd { @include fz-16; }
        @include max-lg-rwd { &:last-child { margin-right: 0; } }
        @include fz-18;
        @include menu-link-style(.5, 1, $theme-color-primary);
    }

    .nav-btn-cta a { height: $header-mh; }

}