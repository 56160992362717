// ---------- LAYOUT ---------- //

@mixin layout-style($pt, $pb, $px, $px-lg) {
    padding: $pt $px $pb $px;
    @include min-xl-rwd {
        padding-right: $px-lg; 
        padding-left: $px-lg; 
    }
}
@mixin layout-style-banner($pt, $pb, $px, $px-lg, $pt-reset) {
    padding: $pt $px $pb $px;
    @include min-xl-rwd {
        padding-right: $px-lg; 
        padding-left: $px-lg; 
    }
    @include max-lg-rwd { padding-top: $pt-reset }
}

@mixin layout-content-fullwidth {
    [class*=page-section-] {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        @include min-xl-rwd {
            padding-right: 3rem;
            padding-left: 3rem;
        }
    }
}

// ---------- UTLITIES ---------- //

// Background
@mixin bg-hover-light { background-color: $md-grey-35; }
@mixin bg-active-light { background-color: $md-grey-75 ; }
@mixin bg-active-light-index { background-color: $md-grey-75 !important; }

// Font-weight
@mixin fw-400 { font-weight: 400; }
@mixin fw-600 { font-weight: 600; }

// ---------- SCROLL BAR ---------- //

@mixin scroll-x($scroll-type) {
    overflow-x: $scroll-type;
    &::-webkit-scrollbar { height: 6px; }
    &::-webkit-scrollbar-track { background-color: $md-grey-35; }
    &::-webkit-scrollbar-thumb { background-color: $theme-terthiary; }
    &::-webkit-scrollbar-thumb:hover { background-color: $md-grey; }
}
@mixin scroll-y($scroll-type) {
    overflow-y: $scroll-type;
    &::-webkit-scrollbar { width: 6px; }
    &::-webkit-scrollbar-track { background-color: $md-grey-35; }
    &::-webkit-scrollbar-thumb { background-color: $theme-terthiary; }
    &::-webkit-scrollbar-thumb:hover { background-color: $md-grey; }
}

// ---------- OTHERS ---------- //

// Link style
@mixin link-style {
    color: $link;
    &:hover, &:focus { 
        color: $link;
        text-decoration: underline;
    }
}

@mixin menu-link-style($py, $px, $color) {
    display: flex;
    flex-direction: row;
    text-align: center;
    padding: $py + rem $px + rem;
    border-radius: .25rem;
    color: $color;
    transition: .2s ease-in-out;
    &:hover, &:focus { 
        background-color: $md-grey-35;
        text-decoration: none;
    }
}

@mixin state-interaction-element {
    $size-sie: 32px;
    min-width: $size-sie;
    width: $size-sie;
    min-height: $size-sie;
    height: $size-sie;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .25rem;
    cursor: pointer;
    position: relative;
    transition: .2s ease-in-out;

    &:hover, &:focus { @include bg-hover-light; }
}

// Features list
@mixin features-list {
    ul.features-list {
        li { 
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 1rem;
            &:last-child { margin-bottom: 0; }
    
            svg { min-width: 16px; }
            span { margin-left: 1rem; }
        }
    }
}

@mixin features-pack {
    ul.features-pack {
        li {
            display: flex;
            align-items: baseline;
            position: relative;
            top: 2px;
            margin-bottom: 1rem;
            &:last-child { margin-bottom: 0; }

            svg.tick-icon {
                min-width: 1rem;
                width: 1rem;
                fill: $success;
                margin-right: .75rem;
            }
        }
    }
}

@mixin price {
    $fz-price: 64px;
    font-size: $fz-price;
    line-height: calc(#{$fz-price} + #{$lh-default});
    font-weight: 600;
    > span { @include fz-18; }
}