#writingFranklin {

    min-height: calc(100vh - #{$header-mh});

    /* ----- SIDEBAR ----- */
    #sidebarMenu {

        .articles-wrapper {
            min-height: 150px;
            max-height: 390px;
            @include min-xl-rwd { min-height: 390px; }
            @include scroll-y(auto);

            .article-item {
                position: relative;
                cursor: pointer;
                transition: .2s ease-out;

                &:hover, &:focus { 
                    @include bg-hover-light;
                    .article-menu { visibility: visible; }
                }
                &.article-selected { 
                    @include bg-active-light;
                    .article-menu { visibility: visible; }
                }
                
                p {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    &::first-letter { text-transform: uppercase; }
                }

                .article-menu {
                    visibility: hidden;
                    transition: none !important;
                    outline: none;
                    @include state-interaction-element;
                    &:hover, &:focus { background-color: $md-grey; }

                    > ul.article-submenu {
                        background-color: $theme-color-secondary;
                        border: 1px solid $theme-terthiary;
                        box-shadow: 0 0 4px $black-25;
                        text-align: right;
                        right: 48px;
                        top: 0;
                        z-index: 1;

                        li {
                            padding: .25rem .5rem;
                            &:hover, &:focus { background-color: $md-grey-35; }
                        }
                    }
                }
            }
        }
    }

    /* ----- HEADING ----- */
    .writing-franklin-header {
        &-top { border-bottom: 1px solid $theme-terthiary; }
    }

    /* ----- ONBOARDING ----- */
    .onboarding-content {
        border-top: 1px solid $theme-terthiary;

        .video-onboarding {
            @include min-lg-rwd { width: 75%; }
            @include max-lg-rwd { width: 100%; }
            @include max-sm-rwd { height: 200px; }
        }
    }
    
    /* ----- WRITING MODE ----- */
    .block-writing {
        /*
        @include min-lg-rwd { border-right: 1px solid $theme-terthiary; }
        @include max-lg-rwd { border-bottom: 1px solid $theme-terthiary; }
        */

        // Introduction
        .tags-container {
            border-radius: .5rem;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;

            &-hover { background-color: rgba($light-grey, .5); }
            &-focus { background-color: $light-grey; }
        }
        
        .intro-error { color: $danger; }
        
        .section:hover, .section:focus {
            .state-interaction-element { visibility: visible; }
        }

        textarea {
            width: 100%;
            min-height: 54px;
            margin: 0 .5rem 1rem auto;
            padding: 1rem;
            border-radius: .5rem;
            border: none;
            text-align: left;
            word-break: break-word;
            resize: none;
            // transition: .2s ease-in-out; // Prevent bug when textarea is resized
            @include scroll-y(auto); // Lack 1px from <Textarea/> lib used component

            &::placeholder {
                color: $text-disabled;
                @include fz-16;
            }

            &:hover { background-color: rgba($light-grey, .5); }
        
            &:focus { 
                background-color: $light-grey;
                outline: none;
            }

            &.title {
                min-height: 74px;
                margin-right: 0;
                text-align: center;
                @include fz-36;
                @include max-lg-rwd { @include fz-26; } 
                @include fw-600;

                &::placeholder { 
                    @include fz-36;
                    @include max-lg-rwd { @include fz-26; } 
                }

                &:empty::before {
                    content: attr(data-placeholder);
                    color: $text-disabled;
                    @include fw-400;
                    display: block; // Compatibility
                    pointer-events: none;
                }
            }

            &.introduction {
                margin-right: 0;
                margin-bottom: .75rem;
                &.top-radius-0 {
                    border-top-right-radius: 0; 
                    border-top-left-radius: 0; 
                }
                &-hover { background-color: rgba($light-grey, .5);  }
            }

            &.h2 {
                min-height: 70px;
                &::placeholder { 
                    @include fz-32; 
                    @include max-lg-rwd { @include fz-22; } 
                }
            }

            &.h3 {
                min-height: 65px;
                &::placeholder { 
                    @include fz-26;
                    @include max-lg-rwd { @include fz-20; } 
                }
            }

            + .state-interaction-element {
                visibility: hidden;
                outline: none;
                transition: none;

                ul.article-menu-tag {
                    background-color: $theme-color-secondary;
                    border: 1px solid $theme-terthiary;
                    box-shadow: 0 0 4px $black-25;
                    text-align: right;
                    right: 36px;
                    top: 0;
                    z-index: 1;

                    li {
                        padding: .25rem .5rem;
                        &:hover, &:focus { background-color: $md-grey-35; }
                        &.tag-active { background-color: $md-grey-75; }
                        &[data-type=h2] { 
                            @include fz-20;
                            @include fw-600;
                        }
                        &[data-type=h3] { 
                            @include fz-18;
                            @include fw-600;
                        }
                        &[data-type=text] { @include fz-16; }
                    }
                }
            }
        }

        // Button generate section
        .generate-content {
            width: 160px;
            height: 32px;
        }
        .generate-intro {
            width: 200px;
            height: 32px;
        }

        // Button add new section
        .add-section {
            &::before {
                content: '';
                width: 100%;
                height: 2px;
                background: $md-grey-35;
                display: block;
                position: relative;
                top: 18px;
            }

            > .state-interaction-element {
                background-color: $md-grey-35;
                &:hover, &:focus { background-color: $md-grey-75; }
            }

        }
    }

    /* ----- SEO BLOCK ----- */
    #seoMenu {
        .density-rendering {
            &:last-child {
                padding-bottom: 0 !important;
                border-bottom: none !important;
            }
        }
    }

    /* ----- WRITING FRANKLIN V2 ----- */
    &.writing-mode {
        .block-writing {
            @include min-lg-rwd { border-right: 1px solid $theme-terthiary; }
            @include max-lg-rwd { border-bottom: 1px solid $theme-terthiary; }
        }
            
        + .wf-footer {
            bottom: 0;
            left: 0;
            min-height: $header-mh;
            z-index: 96;
        }
    }

}