.accordion {

    .card {
        background-color: $theme-secondary;
        border: 1px solid $theme-terthiary !important;
        &:not(:last-child) { margin-bottom: 2rem; }

        &-header {
            background-color: $theme-secondary;
            &:hover {
                cursor: pointer;
                .btn-collapsible { background-color: $md-grey-35; }
            }
            .btn-collapsible {
                min-width: 28px;
                width: 28px;
                min-height: 28px;
                height: 28px;
                @include fz-26;
                cursor: pointer;
                transition: .2s ease-in-out;
                &:hover, &:focus { background-color: $md-grey-35; }
            }
        }

        &-body {
            border-top: 1px solid $theme-terthiary;
        }
    }       
}