button[disabled] .pmy-btn {
    cursor: not-allowed;
    background-color: $primary-75;
    border: 1px solid $primary-75;
    &:hover, &:focus { box-shadow: none; }
}

.pmy-btn {
    padding: .5rem 1rem;
    background-color: transparent;
    color: $primary;
    border: none;
    outline: none !important;
    transition: .2s ease-in-out;
    
    &:hover, &:focus { text-decoration: none !important; }
    &[disabled] { cursor: not-allowed; }

    > span { pointer-events: none; } // To fix bug when click on CTA is not declenched

    &-outline-light {
        border: 1px solid $primary;
        color: $primary;

        svg { fill: $primary; }

        &:hover, &:active { 
            background-color: $primary-25;
            color: $primary;
        }
        &:focus { 
            background-color: $primary-25;
            color: $primary;
            box-shadow: none !important;
            outline: none;
        }
    }

    &-outline-full {
        border: 1px solid $primary;
        color: $primary;

        svg { fill: $primary; }

        &:hover, &:focus, &:active { 
            background-color: rgba($primary, .9);
            color: $theme-color-secondary;
            box-shadow: 0 0 8px $black-25;

            svg { fill: $theme-color-secondary; }
        }
    }

    &-full {
        background-color: $primary;
        color: $theme-color-secondary;
        border: 1px solid $primary;

        svg { fill: $theme-color-secondary; }

        &:hover, &:focus, &:active { 
            background-color: rgba($primary, .9);
            color: $theme-color-secondary;
            box-shadow: 0 0 8px $black-25; 
        }

        &[disabled] { 
            background-color: $primary-75; 
            border: 1px solid $primary-75; 
            &:hover, &:active { box-shadow: none; }
        }
    } 
}