/* ----- GLOBAL STYLE MODAL ----- */
body {

    &.modal-open .modal-dialog.lateral-modal { transform: translate(0, 0) !important; }

    .modal {
        padding: 0 !important;
        @include scroll-y(auto);

        &.fade {
                
            /* ----- LATERAL MODAL ----- */
            .modal-dialog.lateral-modal {
                max-width: 100% !important;
                width: 50%;
                margin: 0;
                margin-top: auto;
                margin-left: auto;
                box-shadow: -4px 0 16px $black-25;
                transform: translate(100%, 0);
                transition: .4s ease-in-out;

                @include min-xl-rwd { width: 33%; }
                @include max-md-rwd { width: 80%; }

                .modal-content {
                    min-height: 100vh;
                    display: block;
                    border: none;
                    border-radius: 0;
        
                    .modal-header { border-bottom: 1px solid $theme-terthiary; }
        
                    .modal-footer { 
                        > * { margin: 0; }
                        border-top: 1px solid $theme-terthiary;
                    }
                }
            }

            /* ----- BASIC MODAL ----- */
            .modal-dialog.custom-modal, .modal-dialog.reset-modal {
                width: 100%;
                margin: 0;
                margin-top: auto;
                margin-bottom: auto;
                border-radius: .5rem;
                box-shadow: 0 0 16px $black-25;
                transition: .4s ease-in-out;
                @include max-sm-rwd { width: 90%; }

                .modal-content {
                    display: block;
                    border: none;
                    border-radius: .5rem;
                    @include scroll-x(hidden);

                    .modal-header { border-bottom: 1px solid $theme-terthiary; }
        
                    .modal-footer { 
                        > * { margin: 0; }
                        border-top: 1px solid $theme-terthiary;
                    }
                }
            }

            // RELOAD CREDITS MODAL
            .modal-dialog.reload-credits-modal {
                max-width: $mw-container;
                width: 100%;
                margin: auto;
                padding: 3rem;
                @include max-lg-rwd { padding: 1.5rem 1rem; }    
                
                .pack-credits {
                    @include min-lg-rwd { max-width: calc(33.333333% - 1.5rem); }
                }
            }

        }
    
    }
   
}

/* ----- FULL MODAL ----- */
.full-modal { 
    max-width: 100%; 
    margin: 0;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-content {
        width: 80%;
        height: 80%;
        position: fixed;
        border: none;
        border-radius: .5rem;
    }
}

/* ----- IA SETTING : WRITING FRANKLIN ----- */
.modal .modal-content {
    
    .btn-choices-container {
        button {
            padding: .25rem .5rem;
            border: 1px solid $theme-terthiary;

            &:hover, &:focus {
                background-color: $primary-25;
                border-color: $primary-25;
            }

            &.parameter-selected {
                background-color: $primary;
                color: $theme-color-secondary;
                border-color: $primary;
            }

            &:first-child {
                border-top-left-radius: .25rem;
                border-bottom-left-radius: .25rem;
            }
            &:last-child {
                border-top-right-radius: .25rem;
                border-bottom-right-radius: .25rem;
            }
        }
    }

    .bgc-info { background-color: rgba($primary, .1); }

}