@mixin video($h, $path) {
    height: $h;
    min-height: $h;
    background-image: url($path);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: $theme-secondary;
    border-radius: .5rem;

    &:hover {
        .video-tooltip {
            bottom: -48px;
            opacity: 1;
            visibility: visible;
        }
    }

    .video-tooltip {
        background-color: $secondary;
        bottom: -24px;
        opacity: 0;
        visibility: hidden;
        z-index: -1;
        transition: .4s ease-in-out;

        &::before {
            content: '';
            margin: 0 auto;
            width: fit-content;
            position: absolute;
            left: 0;
            right: 0;
            top: -8px;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 8px solid $secondary;
        }
    }

    .play-btn {
        transition: .4s ease-in-out;
        svg { fill: $secondary; }

        &:hover, &:focus {
            transform: scale(1.15);
            svg { fill: rgba($secondary, .9); }
        }

        &::before, &::after {
            content: '';
            position: absolute;
            border: 3px solid $secondary;
            border-radius: 50%;
            top: -10px;
            left: -10px;
            right: -10px;
            bottom: -10px;
            animation: circular 1.5s linear infinite;
            opacity: 0;
        }
        &::after { animation-delay: 0.5s; }

        @keyframes circular {
            0% { transform: scale(.8); }
            50% { opacity: 1; }
            100% { transform: scale(1.0); }
        }
    }

}

.video-container-home { @include video(330px, '../../../img/png/illustrations/thumbnail-video.jpg'); }
.video-container-onboarding { @include video(330px, ''); }
.video-onboarding-tool { @include video(250px, ''); }