.layout-style { 
    @include layout-style($header-mh, 6rem, 1.5rem, 3rem);
    min-height: 100vh;
}
.layout-style-banner { 
    @include layout-style-banner($header-mh + $banner-promotion, 6rem, 1.5rem, 3rem, $header-mh);
    min-height: 100vh;
}

.h-100-vh {
    min-height: calc(100vh - #{$header-mh});
}
.h-100-vh-banner {
    min-height: calc(100vh - (#{$header-mh} + #{$banner-promotion}));
}

.container {
    padding-right: 0;
    padding-left: 0;
    @include min-xl-rwd { max-width: $mw-container !important; }
    @include max-lg-rwd { max-width: 100% !important; }
}
.row {
    margin-right: 0;
    margin-left: 0;
}