@keyframes alert-anim-lg {
    from { opacity: 0; top: 0; }
    to { opacity: 1; }
}
@keyframes alert-anim-md {
    from { opacity: 0; top: 0; }
    to { opacity: 1; }
}

.alert-msg {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    min-height: 42px;
    color: $theme-color-secondary;
    padding: 1rem 1.5rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    @include fz-18;
    z-index: 50;
    transition: .4s ease-in-out;
    @include min-xl-rwd { padding: 1rem 3rem; }

    &-no-sticky {
        position: initial;
        margin-top: 1.5rem;
        padding: 1rem;
        border-radius: .25rem;
    }

    &-visible { 
        opacity: 1;
        animation: alert-anim .6s ease-in-out;
        @include min-lg-rwd { 
            animation: alert-anim-lg .6s ease-in-out;
            top: calc(#{$header-mh} + #{$banner-promotion});
        }
        @include max-lg-rwd {
            animation: alert-anim-md .6s ease-in-out;
            top: $header-mh;
        }
    }
    &-no-banner { top: $header-mh; }

    &#successMessage { background-color: $success; }
    &#errorMessage { background-color: $danger; }

    a {
        color: $theme-color-secondary;
        text-decoration: underline;
    }
    
}