$input: '.input-primary';

#{$input}-wrapper {
    margin-bottom: 16px;
    padding-bottom: 16px;

    // Display
    &.input-password-column {
        #{$input}-label { flex-direction: column !important; }
        #{$input} + #{$input}-icon { 
            top: 58px;
            @include max-sm-rwd { top: 78px; }
        }
    }

    // Replace icon if label is hidden
    #{$input}-label.d-none + #{$input} + #{$input}-icon { top: 10px; }

    #{$input} {
        min-height: $input-height;
        height: $input-height;
        padding: 12px;
        border: 1px solid $theme-terthiary;
        background-color: $theme-secondary;
        border-radius: .25rem;
        transition: .2s ease-in-out;

        &::placeholder { @include fz-14; }
        &::-webkit-input-placeholder { line-height: normal !important; }
    
        &:focus {
            outline: none;
            border-left: 4px solid $primary;
        }
    
        &[disabled] {
            cursor: not-allowed;
            background-color: $bg-disabled;
        }

        &[type=search] {
            padding-right: 24px;
            &::-webkit-search-cancel-button {
                position: relative;
                right: -12px;
                cursor: pointer;
                opacity: 1;
            }
        }
  
        + #{$input}-icon {
            position: absolute;
            right: 8px;
            top: 38px;
            padding: 5px 8px;
            border-radius: 50%;
            cursor: pointer;
            transition: .2s ease-in-out;
    
            &:hover { background-color: rgba($md-grey, .35); }

            @include max-sm-rwd { top: 58px; }
        }
    }
    
}

#signUp #{$input}-wrapper #{$input},
#profilePage #{$input}-wrapper[class*=input-password-] #{$input} { 
    padding-right: 3rem; 
}