#askingFranklin {

    > main { 
        max-width: 1920px;
        margin-right: auto;
        margin-left: auto;
    }

    #stickyMenu {
        height: min-content;
        top: 6rem;

        &.banner-showed { top: calc(6rem + #{$banner-promotion}); }

        @include min-xl-rwd { 
            flex-basis: calc(25% - 3rem) !important;
            margin-bottom: 3rem;
            z-index: 1;
        }
        @include max-xl-rwd { position: initial !important; }

        /* ----- INFORMATIONS ----- */
        h1 {
            word-break: break-all;
            @include fz-18;
            @include fw-600;
            &::first-letter { text-transform: uppercase; }
        }
        
        ul > li {

            a.nav-link-style {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: .5rem 1.5rem;
                color: $theme-color-primary;
                transition: .2s ease-in-out;
                
                &:hover, &:focus { 
                    background-color: $md-grey-35;
                    color: $theme-color-primary;
                    text-decoration: none;
                }
            }

            &.nav-link-style-active {
                a {
                    background-color: $md-grey-75 !important;
                    color: $primary !important;
                }
            }
        }

        /* ----- FORM NEW SEARCH ----- */
        .sticky-menu-new-search {
            margin-top: 0 !important;
            @include min-xl-rwd { flex-direction: column !important; }
            @include max-xl-rwd { flex-direction: row !important; }
            @include max-sm-rwd { flex-direction: column !important; }

            .input-primary-wrapper { 
                @include min-xl-rwd {
                    margin-right: 0 !important;
                    margin-bottom: 1rem !important;
                }
                @include max-xl-rwd {
                    margin-right: 1.5rem !important;
                    margin-bottom: 0 !important;
                }
                @include max-sm-rwd {
                    margin-right: 0 !important;
                    margin-bottom: 1rem !important;
                }
            }

            .btn-submit-ctn { 
                height: $input-height !important; 
                @include min-xl-rwd { width: 100%; }
            }

            p { 
                max-width: fit-content;
                color: $link;
                cursor: pointer;
                &:hover, &:focus { text-decoration: underline; }
            }
        }

        @include min-xl-rwd {
            .filters-options-research {
                display: none !important;

                &-visible {
                    display: flex !important;
                    flex-direction: column !important;
                    position: absolute;
                    bottom: 0;
                    left: 100%;
                    width: 100%;
                    background: $theme-secondary;
                    border: 1px solid $theme-terthiary;
                    padding: 1rem;
                    padding-top: 0;
                    margin-left: .5rem;
                    border-radius: .5rem;
                }
            }
        }

    }

    /* ----- RESULTS BLOCK ----- */
    .block-results {
        @include min-xl-rwd { max-width: calc(75% - 3rem); }

        .block-wrapper { 
            padding-top: 6rem;
    
            .asking-franklin-header {
                .btn-export-to-png { 
                    @include min-md-rwd { 
                        top: -56px;
                        min-width: fit-content;
                    }
                }
                .tabs-container { 
                    @include scroll-x(auto);
                    @include max-md-rwd {
                        min-width: calc(100% + 32px);
                        right: 16px;
                    }
                }
            }
            .asking-franklin-body { @include scroll-x(auto); }

            .asking-franklin-dataviz {
                &-graph {
                    min-height: 1000px;
                    > div { @include max-custom-rwd(1366) { padding: 0 6rem !important; } } 
                }
            }

            .asking-franklin-table-tendancies {
                // Blur elements
                .text-blur {
                    color: transparent;
                    text-shadow: 0 0 8px $black-75;
                    user-select: none;
                    -moz-user-select: none;
                }
                .td-area-chart-blur { filter: blur(12px); }
                .external-link-hidden {
                    opacity: 0;
                    pointer-events: none;
                }

                // CTA when content is blured
                .block-tendancies-is-blured {
                    background-color: $theme-secondary;
                    max-width: 75%;
                    position: absolute;
                    right: 0;
                    left: 0;
                    margin: 9rem auto 0 auto;
                    padding: 24px;
                    text-align: center;
                    border-radius: .5rem;
                    z-index: 5;
                    box-shadow: 0 0 16px $black-25;

                    .keyword-trends {
                        display: inline-block;
                        &::first-letter { text-transform: uppercase; }
                    }
                }
            }

        }
    }

}