#home {

    @include layout-content-fullwidth;
    
    /* ----- Blob ----- */
    .blob-1 { 
        @include decoration(initial, initial, -75px, -75px, -1, 190);
        @include min-xxl-rwd { transform: scale(0.7); }
        @include max-xxl-rwd { bottom: 0; }
        @include max-xl-rwd { top: 0; right: 200px; bottom: initial; left: initial; z-index: -3; }
    }
    .blob-2 { 
        @include decoration(-50px, 35%, initial, initial, -3, 95);
        @include min-xxl-rwd { transform: scale(0.7); }
        @include max-xl-rwd { right: initial; left: 20px; }
    }
    .blob-3 { 
        @include decoration(initial, 160px, -100px, initial, -3, 140);
        @include min-xxl-rwd { transform: scale(0.7); }
        @include max-xl-rwd { right: -50px; bottom: -65px; }
    }
    .blob-4 { 
        @include decoration(initial, 0, 30px, initial, -1, 340);
        @include min-xxl-rwd { transform: scale(0.7); }
    }
    .blob-5 { 
        @include decoration(-10%, initial, initial, 100px, 1, 280);
        @include min-xxl-rwd { transform: scale(0.7); }
        @include max-lg-rwd { top: -5%; }
    }
    .blob-6 { 
        @include decoration(-70px, 70px, initial, initial, 0, 150);
        @include min-xxl-rwd { transform: scale(0.7); }
    }
    .blob-7 { 
        @include decoration(initial, initial, 75px, -75px, 0, 335);
        @include min-xxl-rwd { transform: scale(0.7); }
    }
    .blob-8 { 
        @include decoration(initial, initial, 250px, -50px, -1, 135);
        @include min-xxl-rwd { transform: scale(0.7); }
    }
    .blob-9 { 
        @include decoration(initial, 40%, 80px, initial, -1, 190);
        @include min-xxl-rwd { transform: scale(0.7); }
        @include max-xxl-rwd { bottom: 40px; z-index: -3; }
        @include max-lg-rwd { bottom: 0; }
        @include max-sm-rwd { right: 25%; }
    }

    /* ----- Dots ----- */
    .dots-1 { 
        @include decoration(initial, 26%, -220px, initial, -4, 0);
        @include min-xxl-rwd { transform: scale(0.7); }
        @include max-xxl-rwd { bottom: -110px; }
        @include max-xl-rwd { right: 18%; }
    }
    .dots-2 { 
        @include decoration(initial, 0, -50px, initial, 0, 0);
        @include min-xxl-rwd { transform: scale(0.7); }
    }
    .dots-3 { 
        @include decoration(-40px, -95px, initial, initial, -1, 0);
        @include min-xxl-rwd { transform: scale(0.7); }
        @include max-sm-rwd { top: initial; right: 30px; bottom: 0; }
    }
    .dots-4 { 
        @include decoration(95px, 20%, initial, initial, -3, 0);
        @include min-xxl-rwd { transform: scale(0.7); }
    }
    .beta2 {
        font-weight: 800;
    }
    /* ----- Global ----- */
    .catchphrase {
        @include min-xxl-rwd {
            font-size: 24px;
            line-height: 32px;
        }
        @include max-lg-rwd {
            font-size: 18px;
            line-height: 26px;
        }
    }

    /* ----- Hero ----- */
    .page-section-1 {
        padding-bottom: 12rem;

        &.pt-hero-banner { padding-top: calc(6rem + #{$header-mh} + #{$banner-promotion}); }
        &.pt-hero { padding-top: calc(6rem + #{$header-mh}); }

        &::before {
            @include bg-img(0 0, contain, 1107px, 0, initial, initial, 0, -2);
            background-image: url(../../img/svg/decorating/waves/wave-primary.svg);
        }

        h1 {
            font-size: 48px;
            line-height: 56px;
            font-weight: 600;
            @include max-lg-rwd {
                font-size: 32px;
                line-height: 40px;
            }
        }

        .subtitle {
            color: $primary;
            font-size: 42px;
            line-height: 50px;
            font-weight: 400;
            @include max-lg-rwd {
                font-size: 28px;
                line-height: 36px;
            }
        }

        .catch-features {
            li svg {
                min-width: 16px;
                fill: $primary;
            }
        }

        .col-video-ctn { height: fit-content; }
    }
    
    /* ----- Partners ----- */
    .page-section-2 {
        .grid-container {
            display: grid;
            display: -ms-grid;
            grid-template-columns: repeat(6, 1fr);
            -ms-grid-columns: repeat(6, 1fr);
            grid-template-rows: 1fr;
            -ms-grid-rows: 1fr;
            gap: 3rem 3rem;
            grid-template-areas: 
                '. . . . . .';

            @include max-lg-rwd {
                grid-template-columns: repeat(3, 1fr);
                -ms-grid-columns: repeat(3, 1fr);
                grid-template-rows: repeat(2, 1fr);
                -ms-grid-rows: repeat(2, 1fr);
                grid-template-areas:
                    '. . .'
                    '. . .';
            }

            @include max-sm-rwd {
                grid-template-columns: repeat(2, 1fr);
                -ms-grid-columns: repeat(2, 1fr);
                grid-template-rows: repeat(3, 1fr);
                -ms-grid-rows: repeat(3, 1fr);
                grid-template-areas:
                    '. .'
                    '. .'
                    '. .';
            }

            img { 
                margin: auto;

                &.partners {
                    max-height: 25px;
                    &-md { max-height: 35px; }
                    &-lg { max-height: 50px; }
                }
            }
        }
    }

    /* ----- Testimonials ----- */
    .page-section-3 {
        .slider-container {
            @include max-lg-rwd { 
                @include scroll-x(auto);
                overflow-y: hidden;
            }
            .slider-item {
                @media all and (min-width: $screen-lg-min) and (max-width: $screen-xl-max) {
                    max-width: calc(33.333333% - 16px);
                    &:not(:last-of-type) { margin-right: 1.5rem; }
                }
                @include max-lg-rwd { 
                    margin-top: 0 !important;
                    padding-right: 1.5rem !important;
                    min-width: 80%;
                    max-width: 80%;
                    &:first-of-type { margin-left: 1.5rem; }
                }
            }
        }
    }

    /* ----- Features ----- */
    .page-section-4 {
        margin-top: 12rem;
        margin-bottom: 15rem;
        background-color: $theme-secondary;

        .wave-top-1 { @include waveSeparator(149px, -149px, 0, initial, initial, 1, 0); }
        .wave-bottom-1 { @include waveSeparator(149px, initial, initial, -149px, 0, 1, 180); }

        .img-feature {
            height: fit-content;
            border-radius: .5rem;
            box-shadow: 0 0 4px $black-25;
            z-index: 1;
        }
    }

    /* ----- Usecases ----- */
    .page-section-5 {
        padding-bottom: 9rem;

        .blog-post-item {
            @include min-lg-rwd { 
                max-width: calc(33.33333% - 3rem);
                margin-bottom: 4rem;
            }

            $imgSize: 225px;

            &-header {
                max-width: 100%;
                min-width: 100%;
                min-height: $imgSize;
                max-height: $imgSize;
                background-color: $light-grey;
                border: 1px solid $theme-terthiary;
                border-radius: .5rem;
                height: 225px;
                &:hover img, &:focus img { transform: scale(1.1); }

                img {
                    max-width: 100%;
                    min-width: 100%;
                    min-height: $imgSize;
                    max-height: $imgSize;
                    transition: .4s ease-in-out;
                }
            }
            &-body {
                color: $theme-color-primary;
                h3 {
                    $fz-title-blog: $fz-20;
                    font-size: $fz-title-blog;
                    line-height: calc(#{$fz-title-blog} + #{$lh-default});
                }
            }
        }
    }

    /* ----- FAQ ----- */
    .page-section-6 {
        padding-bottom: 9rem;
        &::before {
            background-image: url(../../img/svg/decorating/waves/wave-pre-ending.svg);
            @include bg-img(100% 100%, contain, 736px, initial, 0, 0, initial, -2);
        }
    }
    
    /* ----- CTA ----- */
    .page-section-7 {
        background-color: $theme-secondary;
        padding-bottom: 9rem;
    }

}