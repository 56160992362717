.CookieConsent {
    background-color: $theme-secondary !important;
    border-top: 1px solid $theme-terthiary;
    color: $theme-color-primary !important;
    z-index: 98 !important;
    box-shadow: 0 0 4px $black-25;

    > div:first-child { 
        margin: .5rem 1rem 0 1rem !important;
        flex: initial !important;
    }

    #rcc-confirm-button {
        background-color: transparent !important;
        border-radius: .25rem !important;
        color: $primary !important;
        border: 1px solid $primary !important;
        margin: .5rem 1rem !important;
        font-weight: 600;
        transition: .2s ease-in-out;

        &:hover, &:focus {
            background-color: $primary !important;
            color: $theme-color-secondary !important;
        }
    }
}