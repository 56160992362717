.asking-franklin-table {

    table {

        thead {
            th {
                color: $theme-color-secondary;
                background-color: $theme-color-primary;
                height: 48px;
                padding: 0 .5rem !important;
                border: none;
                &::first-letter { text-transform: capitalize; }
                &:first-child { border-radius:  .25rem 0 0 .25rem; }
                &:last-child { border-radius: 0 .25rem .25rem 0; }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid $theme-terthiary;
                &:last-child { border-bottom: none; }
                &:nth-child(odd) { background-color: $light-grey; }

                td { padding: .25rem .5rem !important; }

                a {
                    transition: .2s ease-in-out;
                    &:hover { background-color: $md-grey-35; }
                }
            }
        }
    }

}

.asking-franklin-table-tendancies {

    @include max-md-rwd {

        thead {
            th:not(:first-child) { display: none; }
        }

        tbody {
            tr {
                display: grid;
                border-bottom: none !important;

                td:first-child {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;
                }

                .td-area-chart { 
                    border-top: none;

                    .recharts-wrapper {

                        @include max-sm-rwd {
                            width: 207px !important;
                            height: auto !important;
                            top: 7px !important;
                            svg { 
                                width: 207px !important;
                                height: auto !important;
                            }
                        }
                    }
                }

                td:last-child { display: none; }
            }
        } 

    }

}