.card-testimonial {
    background-color: $theme-secondary;
    border: 1px solid $theme-terthiary;
    border-radius: .5rem;
    padding: 2rem;
    box-shadow: 0 0 8px rgba($black, .04);
    @include max-lg-rwd { height: 100%; }

    img {
        height: 125px;
        pointer-events: none;
    }

    .quote {
        position: absolute;
        top: 175px;
        left: 25px;
        opacity: .35;
    }

    .sm-link {
        width: 26px;
        height: 26px;
        transition: .2s ease-in-out;
        &:hover, &:focus { background-color: $md-grey-35; }
    }
}