::selection {
    background-color: $primary;
    color: $theme-secondary;
}

html { 
    overflow-x: hidden;
    scroll-behavior: smooth;
}

body {
    font-family: $ff-primary, 'Calibri', 'Arial', sans-serif;
    color: $theme-color-primary;
    @include fz-16; 
    background-color: $theme-primary;
    overflow-y: auto;
    overflow-x: hidden;

    // Fix Bootstrap modal
    &.modal-open { padding-right: 0 !important; }
    &.no-y-scroll { overflow-y: hidden !important; }
}

a { @include link-style; }

button { 
    background-color: transparent;
    &:focus { outline: none; }
}

blockquote {
    border-left: 4px solid $primary;
    padding-left: .5rem;
}

.block-style {
    background-color: $theme-secondary;
    border: 1px solid $theme-terthiary;
    padding: 16px 16px 32px 16px;
    border-radius: .5rem;
    overflow: hidden;
}

.state-interaction-element { @include state-interaction-element; }

.price { @include price; }
 
@include features-list;
@include features-pack;

// Typography
.fz-12 { @include fz-12; }
.fz-14 { @include fz-14; }
.fz-16 { @include fz-16; }
.fz-16-index { @include fz-16-index; }
.fz-18 { @include fz-18; }
.fz-20 { @include fz-20; }
.fz-22 { @include fz-22; }
.fz-24 { @include fz-24; }
.fz-26 { @include fz-26; }
.fz-32 { @include fz-32; }
.fz-36 { @include fz-36; }
.fw-400 { font-weight: 400; }
.fw-600 { font-weight: 600; }

.lh-1 { line-height: 1 !important; }

// Border
.border-top { border-top: 1px solid $theme-terthiary !important; }
.border-right { border-right: 1px solid $theme-terthiary !important; }
.border-bottom { border-bottom: 1px solid $theme-terthiary !important; }
.border-left { border-left: 1px solid $theme-terthiary !important; }
.border-full { border: 1px solid $theme-terthiary !important; }

// Colors
.color-primary { color: $primary; }
.color-dark { color: $theme-color-primary; }
.color-light { color: $theme-color-secondary !important; }
.color-success { color: $success !important; }
.color-danger { color: $danger !important; }
.color-disabled { color: $text-disabled; }

// Background
.bgc-primary { background-color: $primary; }
.bgc-dark { background-color: $theme-color-primary; }
.bgc-light { background-color: $theme-color-secondary; }

// Width & Height
.h-max-content { height: max-content; }
.min-h-inherit { min-height: inherit; }
.max-w-100 { max-width: 100% !important; }
.w-max-content { width: max-content; }
.w-fit-content { width: fit-content; }
.w-md-100 { @include max-md-rwd { width: 100%; } }
.w-sm-100 { @include max-sm-rwd { width: 100%; } }

// Padding & Margin
.mt-6 { margin-top: 6rem; }
.pt-6 { padding-top: 6rem; }

// Utilities
.overflow-visible { overflow: visible !important; }
.cursor-pointer { cursor: pointer; }
.pointer-events-none { pointer-events: none; }
