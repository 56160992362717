// ---------- INPUT STYLE ---------- //
.btn-filter {
    min-height: $input-height;
    height: fit-content;
    width: 100%;
    overflow: visible;
    position: relative;
    cursor: pointer;
    background-color: $theme-secondary;
    border: 1px solid $theme-terthiary;
    border-radius: .5rem;
    z-index: 5;

    .btn-filter-header {
        height: $input-height; 
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;

        .data-header {
            background-color: $theme-secondary;
            position: absolute;
            top: -11px;
            left: 8px;
            padding: 0 .5rem;
            @include fz-14;
            @include fw-600;

            + .data-placeholder {
                overflow: hidden;
                max-width: calc(100% - 1.5rem);
                max-height: 22px;
                word-break: break-all;
                text-transform: capitalize;
            }
        }
        svg { transition: .2s ease-in-out; }
    }

    // ---------- FILTER IS OPEN ---------- //

    &.filters-dropdown-open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: 0 0 4px $black-25;

        @include min-xl-rwd { 
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }

        .btn-filter-header svg { transform: rotate(-180deg); }

        .filters-dropdown-container {
            display: block;
            width: calc(100% + 2px); // Fix
            margin-left: -1px; // Fix
            position: absolute;
            border: 1px solid $theme-terthiary;
            border-top: none;
            box-shadow: 0 4px 4px $black-25; 

            &::before {
                content: '';
                display: block;
                margin: 0 1rem;
                border-bottom: 1px solid $theme-terthiary;
            }
        }
        
        .filters-container {
            max-height: 265px;
            padding: 1rem 0;
            overflow-x: hidden;
            @include scroll-y(auto);

            &-multiple {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                padding-right: 1rem;
                padding-left: 1rem;
            }
        }
    }

    &.filters-input-disabled {
        background-color: $bg-disabled;
        cursor: not-allowed;

        .data-header {
            background: linear-gradient($theme-color-secondary, $bg-disabled);
            border-radius: 50% 50% 0 0;

            + .data-placeholder { color: $theme-color-primary; }
        }
    }
}

// ---------- DROPDOWN STYLE ---------- //

.filters-dropdown-container {
    display: none;
    background-color: $theme-secondary;
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
    overflow: hidden; // Fix
    cursor: default;

    .filters-input-container {
        padding: .5rem 1rem;
        position: relative;
        cursor: pointer;

        &:hover { @include bg-hover-light; }

        &.filter-active { 
            @include bg-active-light-index;
            label { color: $primary; }
        }

        input[type=radio] + label {
            pointer-events: none; // Fix when input is clicked
            white-space: nowrap; // Fix

            span:first-of-type { display: none; }
            .radio-label svg { margin-right: .5rem; }
        }
    }
}

// ---------- OVERRIDE ---------- //
#btnCountries { 
    @include min-md-rwd { margin-right: 1.5rem; }
    @include max-md-rwd { z-index: 6; }
}

#askingFranklin {
    @include min-xl-rwd {

        #stickyMenu #btnCountries { margin: 0 !important; }

        .filters-options-research {

            .btn-filter {
                &.filters-dropdown-open {
                    border-radius: .5rem;
                    
                    .filters-dropdown-container {
                        &::before { border: none; }
                        bottom: 70px;
                        border-radius: .5rem;
                        box-shadow: 0 0 4px $black-25;
                    }
                }
            }
        }
    }
}