#faq {

    .categorie-list-faq {
        background-color: $theme-secondary;
        border: 1px solid $theme-terthiary;
        padding: 1.5rem;
        border-radius: .25rem;
        margin-bottom: 1.5rem;
        cursor: pointer;
        transition: .2s ease-in-out;

        @include min-md-rwd { 
            max-width: calc(33.33333% - 16px) !important; 
            &:not(:last-child) { margin-right: 1.5rem; }
        }

        &:hover, &:focus { border-color: $primary-75; }

        &-selected {
            border-color: $primary !important;
            color: $primary !important;
            svg { fill: $primary !important; }
        }
    }

}