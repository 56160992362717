.pmy-tab {
    padding: .5rem 2rem;
    background-color: transparent;
    color: $theme-color-primary;
    border: none;
    border-top: 1px solid transparent;
    border-bottom: 1px solid $theme-terthiary;
    outline: none !important;
    transition: .2s ease-in-out;
    @include max-sm-rwd { padding: .5rem 1rem; }

    &:hover, &:focus { 
        text-decoration: none !important;
        color: $primary;
        border-bottom-color: $primary;
    }

    &[disabled] { 
        cursor: not-allowed;
        &:hover, &:focus {
            color: $theme-color-primary;
            border-bottom-color: $theme-terthiary;
        }
    }

    svg { fill: $theme-color-primary; }

    &-selected {
        background-color: $primary-25 !important;
        color: $primary !important;
        border-bottom: 1px solid $primary !important;

        svg { fill: $primary !important; }
    }
}

.pmy-tab-badge {
    background-color: $secondary;
    color: $theme-color-secondary;
    right: -8px;
    pointer-events: none;
    @include max-sm-rwd { right: -24px; }
}