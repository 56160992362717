input[type=radio] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;

    + label {

        span:first-of-type {
            width: 14px;
            height: 14px;
            margin-right: 1rem;
            display: inline-block;
            vertical-align: middle;
            position: relative;
            border: 1px solid $theme-color-primary;
            cursor: pointer;
            border-radius: 50%;
            
            &::before {
                content: '';        
                opacity: 0;
                width: 8px;
                height: 8px;
                background-color: $primary;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) scale(0);
                border-radius: 50%;
            }
        }
    }

    &:checked { 
        + label span:first-of-type {
            border-color: $primary;

            &::before {
                opacity: 1;
                transform: translate(-50%, -50%) scale(1);
                border-color: $primary;
                transition: .4s ease-in-out;
            }
        }
    }
}