.tabs-container-profile {
    @include scroll-x(auto);
    @include max-md-rwd {
        min-width: calc(100% + 32px);
        right: 16px;
    }
}

.asking-franklin-table-profile {

    table {

        thead {
            th {
                color: $theme-color-secondary;
                background-color: $theme-color-primary;
                height: 48px;
                padding: 0 1rem !important;
                border: none;
                &:first-child { border-radius: .25rem 0 0 .25rem; }
                &:last-child { border-radius: 0 .25rem .25rem 0; }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid $theme-terthiary;
                &:last-child { border-bottom: none; }
                &:nth-child(odd) { background-color: $light-grey; }

                td { padding: .75rem 1rem !important; }
            }
        }
    }
}