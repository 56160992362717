.toggle-switch {

    label > span { 
        min-width: 60px;

        .label-info {
            background-color: $primary-25;
            top: -12px;
        }
    }

    input[type=checkbox] {
        opacity: 0;
        width: 0;

        + .switch-dot {
            width: 36px;
            height: 16px;
            background-color: $text-disabled;
            display: flex;
            align-items: center;
            border-radius: 50px;

            &-active {
                background-color: $primary-25;

                &::before { 
                    background-color: $primary !important;
                    border-color: $primary !important;
                    transform: translateX(100%);
                }
            }

            &::before {
                content: '';
                width: 20px;
                height: 20px;
                background-color: $theme-secondary;
                border: 1px solid $text-disabled;
                border-radius: 50%;
                transition: .2s ease-in-out;
            }
        }
    }

}