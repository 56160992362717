#banner {
    @include min-lg-rwd {
        height: $banner-promotion;
        background-color: $theme-color-primary; 
    }
    height: auto;
    background-color: $primary;
    color: $theme-color-secondary;
    z-index: 97;
    position: fixed;
    top: 0;
    right: 0;
    transition: .4s ease-in-out;
    @include max-lg-rwd {
        top: initial;
        bottom: 0;
    }

    &.banner-not-showed {
        @include min-lg-rwd {
            opacity: 0;
            visibility: hidden;
            top: -$banner-promotion;
        }
        @include max-lg-rwd {
            opacity: 0;
            visibility: hidden;
            transform: translateY(500px);
        }
    }

    .promotion-text {

        .promotion-percentage {
            @include fz-20;
            color: $secondary;
        }
    
        .promotion-code {
            @include fz-20;
            color: $secondary;
        }

        a {
            color: $theme-color-secondary;
            text-decoration: underline;
        }
    } 

    .promotion-timer {
        font-weight: 600;

        &-count {
            text-align: right;
            @include fz-24;
            @include min-lg-rwd { width: 235px; }
        }
        &-ended { @include fz-20; }

        span {
            text-transform: uppercase;
            color: $secondary;
            &:not(:last-child) { letter-spacing: 1rem; }
        }
    }
    
}