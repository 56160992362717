// ---------- WEBSITE GLOBAL LOADER ---------- //

.loading-icon {
    svg rect { fill: $primary; }
}

// ---------- SPINNER ---------- //

@keyframes spinnerRotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    width: 20px;
    height: 20px;
    border: 2px solid $theme-terthiary;
    border-radius: 50%;
    animation: spinnerRotation 1s linear infinite;

    &-light { border-top: 2px solid $theme-color-secondary; }
    &-dark { border-top: 2px solid $theme-color-primary; }
}