.filter-badge-tag {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: fit-content;
    padding: .375rem .5rem;
    margin-right: .5rem;
    margin-bottom: .5rem;
    line-height: 1 !important;
    color: $theme-color-primary;
    border: 1px solid $theme-terthiary;
    border-radius: 1rem;
    cursor: pointer;
    transition: .2s ease-in-out;
    @include fz-14;
    @include fw-600;

    &:hover, &:focus {
        border-color: transparent;
        @include bg-hover-light;
        svg { transform: rotate(45deg) scale(1.15) !important; }
    }

    &:last-of-type { margin-right: 0; }

    span {
        word-break: break-word;
        &::first-letter { text-transform: uppercase; }
    }

    svg { 
        min-width: 8px;
        fill: $theme-color-primary;
        transform: rotate(45deg) !important;
        margin-right: .125rem;
        transition: .2s ease-in-out;
    }

    &-selected {
        color: $theme-color-primary;
        border-color: transparent;
        @include bg-active-light-index;
        &:hover, &:focus { 
            border-color: $md-grey;
            svg { transform: rotate(0) scale(1.15) !important; }
        }

        svg { 
            fill: $theme-color-primary;
            transform: rotate(0) !important;
        }
    }
}